@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: var(--font-primary), sans-serif !important;
  }
}

@layer components {
  .card {
    @apply bg-white rounded border border-solid border-neutral-200 shadow-lg dark:bg-neutral-700;
  }
}

body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: var(--font-primary), sans-serif;
}

.ant-tabs-tab-btn,
.ant-menu-title-content {
  font-family: var(--font-secondary), sans-serif !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  font-family: var(--font-primary), sans-serif !important;
}

.ant-layout-sider,
.ant-layout-sider-trigger {
  border-right: 0;
}

.leaflet-routing-container {
  display: none !important;
}

.ant-tree-draggable-icon {
  color: var(--primary-color);
  opacity: 1 !important;
}

.chart-tooltip-title {
  font-family: Roboto, sans-serif !important;
}

header {
  z-index: 999;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined" !important;
}
